<div class="page-container">
  <div class="flex flex-wrap">
    <app-search-input style="min-width: 35rem" [value]="searchText" [onSearch]="onSearchGroup"></app-search-input>
    <p-button
      (onClick)="onOpenDocument()"
      [disabled]="isFetching || !url"
      class="flex flex-1 justify-content-end"
      icon="pi pi-arrow-up-right"
      [rounded]="false"
      [text]="true"
      [raised]="true"
      severity="info"
      label="Open document in new tab"
    />
  </div>
  <div class="p-fluid container row">
    <user-manual-menu
      [category]="selectedCategory"
      [subCategory]="selectedSubCategory"
      [document]="selectedDocument"
      [search]="searchText"
      (onMenuClick)="onDocumentSelect($event)"
    ></user-manual-menu>
    <p-divider layout="vertical"></p-divider>
    <div class="main-content">
      <app-pdf-viewer *ngIf="reload && url" [url]="url"></app-pdf-viewer>
      <div *ngIf="reload && !url">
        <div class="flex justify-content-center mt-6">
          <i class="pi pi-inbox" style="font-size: 3.5rem"></i>
        </div>
        <div class="flex justify-content-center">No Data</div>
      </div>
    </div>
  </div>
</div>
<p-blockUI [blocked]="isFetching || !reload">
  <i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
</p-blockUI>
