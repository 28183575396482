export const partialSearch = (data: any[], searchQuery: string, excludeKeys: string[] = []) => {
  // Split the search query into individual words
  const searchTerms = (searchQuery || '').trim().toLowerCase().split(' ');

  // Filter the data based on the search terms
  return data.filter((item) => {
    // Check if any of the search terms match the item's properties
    return searchTerms.some((term) => {
      // Iterate through the item's properties and check for partial matches
      const excludingValues: Record<string, any> = {};
      excludeKeys.forEach((itemKey) => (excludingValues[itemKey] = ''));
      return Object.values({ ...item, ...excludingValues }).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(term);
        }
        return false;
      });
    });
  });
};

export const downloadMyFile = ({ downloadLink = '' }) => {
  if (downloadLink) {
    const link = document.createElement('a');
    // link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank');
    link.setAttribute('href', downloadLink);
    // document.body.appendChild(link);
    link.click();
    link.remove();
  }
};
