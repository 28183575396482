<p-panelMenu *ngIf="items.length > 0" [model]="items" [multiple]="true" styleClass="panel-menu-box">
  <ng-template pTemplate="item" let-item>
    <a [routerLink]="item.routerLink" class="p-menuitem-link">
      <div class="flex p-3 p-menuitem-text">
        <i *ngIf="item.items?.length > 0" style="font-size: 1.5rem" [class]="item.expanded ? 'pi pi-sort-down-fill' : 'pi pi-caret-right'"></i>
        <i *ngIf="!(item.items?.length > 0)" class="pi pi-minus"></i>
        <span class="ml-3">
          {{ item.label }}
        </span>
      </div>
    </a>
  </ng-template>
</p-panelMenu>
<div class="empty-menu" *ngIf="!items.length">
  <div class="flex justify-content-center mt-6">
    <i class="pi pi-inbox" style="font-size: 3.5rem"></i>
  </div>
  <div class="flex justify-content-center">No Data</div>
</div>
