import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { MessageModule } from 'primeng/message';
import { CommonSharedModule } from '../common/shared.module';
import { BlockUIModule } from 'primeng/blockui';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MessageService } from 'primeng/api';

import { UserManualComponent } from './user-manual.component';
import { UserManualMenuComponent } from './user-manual-menu/user-manual-menu.component';

@NgModule({
  imports: [
    CommonModule,
    CommonSharedModule,
    ButtonModule,
    FormsModule,
    DropdownModule,
    RadioButtonModule,
    PanelMenuModule,
    DividerModule,
    FieldsetModule,
    MessageModule,
    BlockUIModule,
    SelectButtonModule,
    RouterModule.forChild([
      {
        path: '',
        component: UserManualComponent
      },
      {
        path: ':category',
        component: UserManualComponent
      },
      {
        path: ':category/:subCategory',
        component: UserManualComponent
      },
      {
        path: ':category/:subCategory/:document',
        component: UserManualComponent
      }
    ])
    // ReportsRoutingModule
  ],
  declarations: [UserManualComponent, UserManualMenuComponent],
  providers: [MessageService]
})
export class UserManualModule {}
