import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';

import { categories } from './user-manual.categories.constants';
import { ManualMenuEvent } from './user-manual-menu.types';
import { partialSearch } from 'src/app/common/util/utilities';

@Component({
  selector: 'user-manual-menu',
  templateUrl: './user-manual-menu.component.html',
  styleUrls: ['./user-manual-menu.component.css']
})
export class UserManualMenuComponent implements OnInit, OnChanges {
  @Input({ required: false }) category!: string;
  @Input({ required: false }) subCategory!: string;
  @Input({ required: false }) document!: string;
  @Input({ required: false }) search: string = '';

  @Output() onMenuClick = new EventEmitter<ManualMenuEvent>();

  items: MenuItem[] = [];

  openCategories: Record<string, boolean> = {};
  openSubCategories: Record<string, boolean> = {};

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const searchText = (changes?.search?.currentValue || '').trim();
    // if (searchText && searchText !== this.search) {
    //   this.search = searchText;
    // }
    this.openCategories[changes?.category?.currentValue] = true;
    this.openSubCategories[changes?.subCategory?.currentValue] = true;
    this.getMenuItems(!!searchText);
  }

  get searchText() {
    return this.search?.trim() || '';
  }

  // Fn to filter categories
  private filterCategories = () => {
    const searchTxt = this.search?.trim();
    if (searchTxt) {
      return categories.filter((cat) => {
        const catItems = cat.items;
        const filteredSubCats = catItems.filter((subCat) => {
          const subCatItems = subCat.items;
          const filterDocs = partialSearch(subCatItems, this.search, ['id', 'items']);
          return filterDocs.length > 0;
        });
        return filteredSubCats.length > 0;
      });
    }
    return categories;
  };

  // Fn to filter sub-categories
  private filterSubCategories = (catItems: MenuItem[]) => {
    const searchTxt = this.search?.trim();
    if (searchTxt) {
      return catItems.filter((subCat) => {
        const subCatItems = subCat.items;
        const filterDocs = partialSearch(subCatItems as any[], this.search, ['id', 'items']);
        return filterDocs.length > 0;
      });
    }
    return catItems;
  };

  private toggleCategory = (cat: string) => {
    const selectedValue = this.openCategories[cat];
    this.openCategories[cat] = !selectedValue;
    this.getMenuItems(!!this.search);
  };

  private toggleSubCategory = (subCat: string) => {
    const selectedValue = this.openSubCategories[subCat];
    this.openSubCategories[subCat] = !selectedValue;
    this.getMenuItems(!!this.search);
  };

  // Fn to create Menu Items required as per component
  private getMenuItems = (enforceOpen = false) => {
    const menuItems = this.filterCategories();
    this.items = menuItems.map((catItem) => {
      const obj: MenuItem = {};
      obj.id = catItem.id;
      obj.label = catItem.label;
      const subMenus = catItem.items || [];
      const filteredSubMenus = this.filterSubCategories(subMenus);
      obj.items = filteredSubMenus.map((subCatItem) => {
        const subObj: MenuItem = {};
        subObj.id = subCatItem.id;
        subObj.label = subCatItem.label;
        const filteredItems = partialSearch(subCatItem.items as any[], this.search, ['id']);
        const categoryKey = obj.id || '';
        const subCategoryKey = subObj.id || '';
        subObj.items = filteredItems.map(({ label, id }) => {
          const itemObj: MenuItem = {
            id,
            label,
            routerLink: `/user-manual/${categoryKey?.replace(/_/g, '-')}/${subCategoryKey?.replace(/_/g, '-')}/${id?.replace(/_/g, '-')}`,
            preserveFragment: true,
            queryParamsHandling: 'preserve',
            replaceUrl: true
          };
          itemObj.command = (e) => {
            this.onMenuClick.emit({ category: categoryKey, subCategory: subCategoryKey, document: id });
          };
          itemObj.styleClass = this.isActive(itemObj) ? 'active-report' : 'leaf-nodes';
          return itemObj;
        });
        subObj.command = (e) => {
          this.toggleSubCategory(subObj.id || '');
        };
        subObj.expanded = this.isExpandedSubCategory(subObj);
        return subObj;
      });
      obj.command = (e) => {
        this.toggleCategory(obj.id || '');
      };
      obj.expanded = this.isExpandedCategory(obj);
      return obj;
    });
  };

  private isExpandedCategory = (item: MenuItem) => !!(this.searchText || this.openCategories[item.id || '']);
  private isExpandedSubCategory = (item: MenuItem) => !!(this.searchText || this.openSubCategories[item.id || '']);
  private isActive = (item: MenuItem) => item.id === this.document;
}
