// CGT VIEW
export const cgtViewDocs = [
  {
    id: 'cgt_search',
    label: 'Corporate Action Search'
  },
  {
    id: 'prices',
    label: 'Prices'
  },
  {
    id: 'release_notes',
    label: 'Release Notes'
  }
];

// ACCESS MANAGEMENT
export const accessMgmtDocs = [
  {
    id: 'create_users',
    label: 'Create Users'
  },
  {
    id: 'update_disable_user',
    label: 'Update/Disable User'
  },
  {
    id: 'manage_billing',
    label: 'Manage Billing'
  },
  {
    id: 'upload_logo',
    label: 'Upload Logo'
  },
  {
    id: 'user_sessions',
    label: 'User Session Management'
  }
];

// USER_GROUP_MANAGEMENT
export const userGroupMgmtDocs = [
  {
    id: 'user_group_setup',
    label: 'User Group Setup'
  },
  {
    id: 'user_group_user_mapping',
    label: 'User Group: User Mapping'
  },
  {
    id: 'user_group_portfolio_mapping',
    label: 'User Group: Portfolio Mapping'
  }
];

// PROFILE_MANAGEMENT
export const profileDocs = [
  {
    id: 'change_password',
    label: 'Change password'
  },
  {
    id: 'enable_disable_mfa',
    label: 'Enable/Disable MFA'
  },
  {
    id: 'user_log_out',
    label: 'Log Out'
  },
  {
    id: 'update_profile',
    label: 'Update profile details'
  }
];

// EXTERNAL_DATA
export const externalDocs = [
  {
    id: 'broker_contract_notes',
    label: 'Broker Contract Notes'
  },
  {
    id: 'import_stapled_security_exceptions',
    label: 'Import Stapled Security Exceptions'
  },
  {
    id: 'import_user_prices',
    label: 'Import User Market Prices'
  },
  {
    id: 'import_user_securities',
    label: 'Import User Securities'
  },
  {
    id: 'import_user_security_distributions',
    label: 'Import User Security Distributions'
  },
  {
    id: 'import_user_transactions',
    label: 'Import User Transactions'
  }
];

// USER_SECURITIES
export const userSecDocs = [
  {
    id: 'user_security_management',
    label: 'User Security Setup'
  },
  {
    id: 'user_market_prices',
    label: 'User Market Prices'
  },
  {
    id: 'user_distribution',
    label: 'User Distribution'
  }
];

// REGISTRY
export const registryDocs = [
  {
    id: 'holder_setup',
    label: 'Create Holder'
  },
  {
    id: 'delete_holder',
    label: 'Delete Holder'
  },
  {
    id: 'download_registry_data',
    label: 'Download Registry Data'
  },
  {
    id: 'registry_configurations',
    label: 'Registry Configurations'
  }
];

// CLIENT SETUP
export const clientDocs = [
  {
    id: 'client_setup',
    label: 'Client Setup'
  },
  {
    id: 'client_contact_setup',
    label: 'Client Contact Setup'
  }
];

// PORTFOLIO MANAGEMENT
export const pfDocs = [
  {
    id: 'portfolio_setup',
    label: 'Portfolio Setup'
  },
  {
    id: 'portfolio_processing',
    label: 'Portfolio Processing'
  },
  {
    id: 'move_portfolio',
    label: 'Move Portfolio'
  },
  {
    id: 'delete_portfolio',
    label: 'Delete Portfolio'
  }
];

// TRANSACTIONS
export const transactionDocs = [
  {
    id: 'purchase_transaction',
    label: 'Transaction Entry: Purchase'
  },
  {
    id: 'sale_transaction',
    label: 'Transaction Entry: Sale'
  },
  {
    id: 'rights_transaction',
    label: 'Transaction Entry: Exercise Of Right'
  },
  {
    id: 'options_transaction',
    label: 'Transaction Entry: Exercise Of Option'
  },
  {
    id: 'payment_of_call_transaction',
    label: 'Transaction Entry: Payment Of Call'
  },
  {
    id: 'capital_adjustment_transaction',
    label: 'Transaction Entry: Capital Adjustment'
  },
  {
    id: 'prior_year_losses_transaction',
    label: 'Transaction Entry: Prior Year Losses'
  },
  {
    id: 'delete_transaction',
    label: 'Delete Transaction'
  },
  {
    id: 'import_user_transactions',
    label: 'Import User Transactions'
  }
];

// USER CHOICES
export const userChoiceDocs = [
  {
    id: 'participation_settings',
    label: 'Participation Settings'
  },

  {
    id: 'coop_action_choices_filter',
    label: 'Coop Action Choices Filters'
  },
  {
    id: 'bonus_coop_action_choices',
    label: 'Coop Action Choices: Bonus'
  },
  {
    id: 'rollover_coop_action_choices',
    label: 'Coop Action Choices: Rollover'
  },
  {
    id: 'small_sale_coop_action_choices',
    label: 'Coop Action Choices: Small Sale'
  },
  {
    id: 'normal_dividend_coop_action_choices',
    label: 'Coop Action Choices: Normal Dividend'
  },
  {
    id: 'bonus_dividend_coop_action_choices',
    label: 'Coop Action Choices: Bonus Dividend'
  },
  {
    id: 'delete_coop_action_choices',
    label: 'Delete Coop Action Choices'
  }
];

// STAPLED SECURITIES
export const stapledSecDocs = [
  {
    id: 'stapled_security_exception',
    label: 'Stapled Security Exception'
  }
];

// ACTIVITIES
export const activityDocs = [
  {
    id: 'activities_shortcuts',
    label: 'Navigation from Activities '
  }
];

// REPORTS
export const reportDocs = [
  {
    id: 'activity_report',
    label: 'Activity'
  },
  {
    id: 'cost_change_report',
    label: 'Cost Change'
  },
  {
    id: 'income_report',
    label: 'Income'
  },
  {
    id: 'realised_report',
    label: 'Realised'
  },
  {
    id: 'registry_data_report',
    label: 'Registry Data'
  },
  {
    id: 'unrealised_report',
    label: 'Unrealised'
  }
];
