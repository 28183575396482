import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualMenuEvent } from './user-manual-menu/user-manual-menu.types';
import { categories } from './user-manual-menu/user-manual.categories.constants';
import { downloadMyFile } from '../common/util/utilities';

export interface ManualObj {
  id: string;
  label: string;
  description: string;
  link: string;
  noData: boolean;
}

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.css']
})
export class UserManualComponent {
  searchText = '';
  reload = true;

  isFetching = false;

  selectedCategory: string = '';
  selectedSubCategory: string = '';
  selectedDocument: string = '';

  url = '';
  constructor(private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.getManualParams();
  }

  getManualParams = () => {
    const pathname = location.pathname;
    const pathPrefix = '/user-manual/';
    const reportPathArr = pathname?.replace(pathPrefix, '')?.split('/') || [];
    const [categoryKey, subCategoryKey, documentKey] = reportPathArr;

    const category = categoryKey?.replace(/-/g, '_');
    const subCategory = subCategoryKey?.replace(/-/g, '_');
    const document = documentKey?.replace(/-/g, '_');
    // Check if category is valid if not assign first
    const validCategory = categories.find((item) => item.id === category);
    this.selectedCategory = !validCategory ? categories[0]?.id : category;

    // Check if sub category is valid
    const categoryObj = categories.find((item) => item.id === this.selectedCategory);
    const subCategoryItems = categoryObj?.items || [];
    const validSubCategory = subCategoryItems.find((item) => item.id === subCategory);
    this.selectedSubCategory = !validSubCategory ? subCategoryItems[0]?.id : subCategory;

    // Check if document is valid
    const subCategoryObj = subCategoryItems.find((item) => item.id === this.selectedSubCategory);
    const documentItems = subCategoryObj?.items || [];
    const validDoc = documentItems.find((item) => item.id === document);
    this.selectedDocument = !validDoc ? documentItems[0]?.id : document;

    this.onDocumentSelect({ category: this.selectedCategory, subCategory: this.selectedSubCategory, document: this.selectedDocument });

    // Also persist search
    let { search } = this.activatedRoute.snapshot.queryParams || {};
    this.onSearchGroup(search);
  };

  onDocumentSelect = ({ category, subCategory, document }: ManualMenuEvent) => {
    // console.log(`${category} - ${subCategory} - ${document}`);
    this.selectedCategory = category;
    this.selectedSubCategory = subCategory;
    this.selectedDocument = document;
    this.fetchUserManualData();
  };

  onSearchGroup = (text?: string) => {
    this.searchText = text || '';
    this.router.navigate([location.pathname], {
      queryParams: {
        search: this.searchText
      }
    });
  };

  fetchUserManualData = () => {
    this.isFetching = true;
    this.reload = false;
    this.http.get('api/estm-drive/user-manual', { params: { fileName: this.selectedDocument || '' } }).subscribe({
      next: (res: any) => {
        this.url = res?.url || '';
        this.isFetching = false;
        this.reload = true;
      },
      error: (err) => {
        this.url = '';
        this.isFetching = false;
        this.reload = true;
      }
    });
  };

  onOpenDocument = () => downloadMyFile({ downloadLink: this.url });
}
