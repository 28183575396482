import {
  accessMgmtDocs,
  activityDocs,
  clientDocs,
  externalDocs,
  pfDocs,
  profileDocs,
  registryDocs,
  reportDocs,
  stapledSecDocs,
  transactionDocs,
  userChoiceDocs,
  userGroupMgmtDocs,
  userSecDocs
} from './user-manual.menu.constants';

export const categories = [
  {
    id: 'user_and_account_activities',
    label: 'User & Account Activities',
    items: [
      {
        id: 'access_management',
        label: 'Access Management',
        items: [...accessMgmtDocs]
      },
      {
        id: 'user_group_management',
        label: 'User Group Management',
        items: [...userGroupMgmtDocs]
      },
      {
        id: 'profile',
        label: 'Profile',
        items: [...profileDocs]
      }
    ]
  },
  {
    id: 'data_management',
    label: 'Data Management',
    items: [
      {
        id: 'external',
        label: 'External',
        items: [...externalDocs]
      },
      {
        id: 'user_securities_management',
        label: 'User Securities Management',
        items: [...userSecDocs]
      },
      {
        id: 'registry',
        label: 'Registry',
        items: [...registryDocs]
      }
    ]
  },
  {
    id: 'portfolio_related_data',
    label: 'Portfolio Related Data',
    items: [
      {
        id: 'client_setup',
        label: 'Client Setup',
        items: [...clientDocs]
      },
      {
        id: 'portfolio_management',
        label: 'Portfolio Management',
        items: [...pfDocs]
      },
      {
        id: 'transactions',
        label: 'Transactions',
        items: [...transactionDocs]
      },
      {
        id: 'user_choices',
        label: 'User Choices',
        items: [...userChoiceDocs]
      },
      {
        id: 'stapled_securities',
        label: 'Stapled Securities',
        items: [...stapledSecDocs]
      },
      {
        id: 'activities',
        label: 'Activities',
        items: [...activityDocs]
      }
    ]
  },
  {
    id: 'reports',
    label: 'Reports',
    items: [
      {
        id: 'default',
        label: 'Default',
        items: [...reportDocs]
      }
    ]
  },
  {
    id: 'corporate_actions_view',
    label: 'Corporate Actions View',
    items: [
      {
        id: 'search',
        label: 'Search',
        items: [
          {
            id: 'cgt_search',
            label: 'Corporate Action Search'
          }
        ]
      },
      {
        id: 'prices',
        label: 'Prices',
        items: [
          {
            id: 'prices',
            label: 'Prices'
          }
        ]
      },
      {
        id: 'release_notes',
        label: 'Release Notes',
        items: [
          {
            id: 'release_notes',
            label: 'Release Notes'
          }
        ]
      }
    ]
  }
];
